import { FullPageContainer } from 'components/Container';
import Head from 'next/head';
import { ReactElement } from 'react';
import FourOhFourScreen from 'screens/404Screen';

export default function FourOhFourPage() {
	return <FourOhFourScreen />;
}

FourOhFourPage.getLayout = function getLayout(page: ReactElement) {
	return (
		<>
			<Head>
				<title>Coinshift</title>
				<meta
					name='description'
					content='Treasury Management for DAOs and Companies'
				/>
				<link rel='icon' href='/favicon.ico' />
			</Head>
			<FullPageContainer>{page}</FullPageContainer>
		</>
	);
};
