import PrimaryButton from 'components/Button/PrimaryButton';
import Container from 'components/Container';
import { CoinshiftLogo } from 'components/Header';
import Img from 'components/Img';
import { InternalLink } from 'components/Link';
import Stack from 'components/Stack';
import Subtitle1 from 'components/Typography/Subtitle1';
import Subtitle2 from 'components/Typography/Subtitle2';
import Title from 'components/Typography/Title';
import paths from 'constants/paths';
import React from 'react';
import { useTheme } from 'styled-components';

const FourOhFourScreen = () => {
	const { spacings } = useTheme();
	return (
		<Container>
			<Stack gutter={spacings[64]} alignItems='center' padding={spacings[40]}>
				<CoinshiftLogo />
				<Img
					src='/assets/404.svg'
					width={456}
					alt='page not found illustration'
				/>

				<Stack gutter={spacings[16]} alignItems='center'>
					<Title size='5xl'>404</Title>
					<Subtitle1>Something is missing</Subtitle1>
					<Subtitle2 style={{ marginBottom: spacings[16] }}>
						The page is missing or you entered the link incorrectly
					</Subtitle2>
					<InternalLink href={paths.organisations.root}>
						<PrimaryButton>Go to Dashboard</PrimaryButton>
					</InternalLink>
				</Stack>
			</Stack>
		</Container>
	);
};
export default FourOhFourScreen;
